.main {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 20px;
}
.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.769);
}
.grid-col-3 {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  @media (min-width: 1025px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1024px) and (min-width: 769px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.text-hover {
  &:hover {
    color: rgb(89, 89, 255);
    cursor: pointer;
  }
}
