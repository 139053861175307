.wrapper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}
.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  height: 70%;
  margin: 0 auto;
  padding: 20px;
  background-color: rgb(247, 247, 247);
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgb(135, 135, 135);
}

.main {
  text-align: center;
}
