.application-info {
  height: 300px;
}
.main {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 20px;
}
.dev-mode-text {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  span {
    font-size: 3rem;
    text-transform: uppercase;
  }
}

.editor-wrapper {
  margin-bottom: 20px;
}
