/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #c1c2c4;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.CardPreview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #c1c2c4;
  margin-top: 20px;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0 1px 3px 0 #616161;
  }
  transition: border 150ms ease;
}

.card-badge {
  display: flex;
  justify-content: center;
  height: 32px;
  align-items: center;
  padding: 0.5em 0.75em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 0.25rem;
}

.card-number {
  display: flex;
  margin: 0;
  font-size: 1.5rem;
  white-space: nowrap;
}
