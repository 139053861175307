.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
