.snackbar {
  position: fixed;
  display: flex;
  width: 400px;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 6px 16px;
  border-radius: 4px;
  color: white;
  box-shadow: 0px 0px 5px 3px rgba(148, 148, 148, 0.301);
  z-index: 2;

  @media (max-width: 480px) {
    transform: none;
    width: 300px;
  }
}

.success {
  background-color: #43a047;
}
.error {
  background-color: #b12755;
}
.closeButton {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.637);
    box-shadow: inset 0px 0px 3px rgba(255, 255, 255, 0.646);
    cursor: pointer;
  }
}
.message {
  display: flex;
  align-items: center;
}
.icon {
  font-size: 20;
}
.iconVariant {
  opacity: 1;
  margin-right: 5px;
}
.message-text {
  margin-left: 5px;
}
