//
// Header Brand
//

.kt-header__brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: flex-begin;
  flex-direction: row;
  padding: 0;
  position: relative;

  .kt-header__brand-logo {
    display: flex;
    justify-content: flex-begin;
    align-items: flex-end;
  }

  .kt-header__brand-nav {
    display: flex;
    margin: 0.25rem 0 0 2.5rem;

    .dropdown {
      .btn {
        border-color: rgba(#fff, 0.1);
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    .kt-header--minimize & {
      margin-left: 1.75rem;
    }
  }
}

.kt-header__brand-logo-sticky {
  height: 50px;
}

// Mobile mode(1024px and below)
@include kt-tablet-and-mobile {
  .kt-header__brand {
    display: none;
  }
}
