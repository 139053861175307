.logo-image {
  margin-top: 1rem;
  height: 50px;
  align-self: center;
}

.print-container {
  padding: 20px;
  box-shadow: 0px 0px 3px rgba(128, 128, 128, 0.619);
}

.invoice-header {
  margin-top: 3rem;
  margin-bottom: 4rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 3rem;
  color: black;
  text-align: center;
}

.invoice-text {
  font-size: larger;
}

.uppercase {
  text-transform: uppercase;
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-container,
  .print-container * {
    visibility: visible;
  }
  .print-container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 20mm;
  }
}
